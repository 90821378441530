import React from "react";
import { BaseLayout } from "../components/Layout";

const terms = () => {
  return (
    <BaseLayout page="terms">
      <section className="outerContainer max-w-screen-2xl text-black-950 flex flex-col gap-5 mx-auto">
        <div className=" flex flex-col gap-5 p-5">
          <p className="">
            <span className="font-bold">Service Delivery: </span>between
            ‘Referrer’/’Client’ and ‘Turn the Tides’ (TTT)
          </p>

          <p className="">
            <span className="font-bold">TTT: </span>Service Provider
          </p>

          <p className="">
            <span className="font-bold">Referrer: </span>
            Authorised person from a Professional Organisation who requests the
            service on behalf of the Client and may be responsible for brokering
            payment.
          </p>

          <p className="">
            <span className="font-bold"> Client: </span>
            Person who receives the service provided by TTT{" "}
          </p>
        </div>
        <hr className="w-[25%] self-center" />
        <ul className=" flex flex-col gap-5 p-5">
          <li>
            For the purposes of this agreement there is an assumption that the
            Referrer is the person authorised by their Organisation to make a
            referral on behalf of a Client. TTT is the company under contract to
            the Organisation to deliver the service as specified in the referral
            and quote.
          </li>
          <li>
            TTT also takes referrals directly from Clients who pay for the
            service themselves.
          </li>
          <li>
            TTT aims to follow a simple, transparent process in collaboration
            with the Referrer and/or Client. TTT can be contacted via phone or
            email to request a quote.
          </li>
          <li>
            TTT aims to respond to all emails and phone messages within 48
            hours. During annual leave periods out of office email replies and
            voicemail messages will advise of any variations to this timescale.
          </li>
          <li>
            Preliminary contacts and discussions will cover perceived need,
            location of property and the specific circumstances of the client.
          </li>
          <li>
            A free assessment will be arranged, either via photographs or at the
            property. Quotes will usually be submitted to the referrer or Client
            via email. Where regular cleaning slots are being agreed quotes will
            be given in the body of an email, or via appointment card at the
            Client’s home. Where the assessment takes place at the property,
            photos will be taken and the written quote sent by email to the
            Referrer following the assessment.
          </li>
          <li>
            The email agreement with the Referrer, or card given to the Client,
            will form the Agreement to provide the service.
          </li>
          <li>
            Access to the property and a date/time for the job will be agreed.
            The job will be undertaken in accordance with these arrangements.
          </li>
          <li>
            If applicable, on arrival at the property the condition of the
            interior will be checked against photos taken at assessment. If the
            condition has deteriorated to such an extent that the job will take
            longer, photos will be taken and the Referrer contacted prior to
            commencement of the job. Work will go ahead once agreed in line with
            any new costings and time factors.
          </li>
          <li>
            On completion of the agreed work photos will be taken and sent to
            the Referrer if applicable
          </li>
          <li>
            TTT will invoice the Referrer and/or their administrative
            representative as authorised. Payment by BACS must be made to TTT
            within 28 days of invoice date.
          </li>
          <li>
            Self-funding clients may pay by cash on the date of the service, or
            by BACS transfer following the start of any regular work. TTT are
            happy to discuss methods of payment that will best suit the Client.
            Non-payment will mean that TTT will be unable to continue any work
            started.
          </li>
          <li>
            TTT will request permission from the client to use photos of their
            property on the TTT website or Facebook page. These will be
            anonymous. There is absolutely no obligation to allow the
            photographs to be shared and Clients may request that their photos
            are destroyed following submission to the Referrer. All photos will
            be stored in accordance with GDPR guidance.
          </li>
          <li>
            Turn the Tides, the Referrer or the Client can choose to end the
            service provided at any time without notice or penalty.
          </li>
        </ul>
      </section>
    </BaseLayout>
  );
};
export default terms;
